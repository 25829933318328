import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import { ChainId } from 'ss_dex_sdk'

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '918')

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL }
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds: [ChainId.SLERF_MAINNET, ChainId.SLERF_TESTNET],
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: {
    [NETWORK_CHAIN_ID]: NETWORK_URL,
    // [ChainId.SLERF_MAINNET]: 'https://rpc.slerfchain.xyz',
    // [ChainId.SLERF_TESTNET]: 'https://testnet.slerfchain.xyz/rpc'
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1]
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: 'Uniswap',
  appLogoUrl:
    'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg'
})

const networkConf = {
  [ChainId.SLERF_MAINNET]: {
    chainId: '0x396',
    chainName: 'SlerfChain Mainnet',
    nativeCurrency: {
      name: 'WSLERF',
      symbol: 'WSLERF',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.slerfchain.xyz'],
    blockExplorerUrls: ['https://scan.slerfchain.xyz'],
  },
  [ChainId.SLERF_TESTNET]: {
    chainId: '0x2321',
    chainName: 'SlerfChain Testnet',
    nativeCurrency: {
      name: 'WSLERF',
      symbol: 'WSLERF',
      decimals: 18,
    },
    rpcUrls: ['https://testnet.slerfchain.xyz/rpc'],
    blockExplorerUrls: ['https://testnet.slerfchain.xyz'],
  },
}
export const onSwitchNetwork = () => {
    const ethereum:any  = window.ethereum
    if (ethereum) {
      ethereum
        .request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              ...networkConf[ChainId.SLERF_MAINNET],
            },
          ],
        })
    } else {

    }
  }
